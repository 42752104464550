import React, { Component } from 'react';
import Header from './components/Header';
import Cards from './components/Cards';
import Footer from './components/Footer';

import './App.css';


class App extends Component {
  constructor(props) {
    super(props); 

    this.state = {
      details: [
        {id: 0, name: "Carolina Blue", backgroundColor: "#7BAFD4", hexcode: '#7BAFD4', rgb: '(123, 175, 212)', category: 'Blue, School', full: 'Carolina Blue, #7BAFD4, (123, 175, 212)'},
        {id: 1, name: "Sea Buckthorn", backgroundColor: "#EB974E", hexcode: '#EB974E', rgb: '(235, 151, 78)', category: 'Orange', full: 'Sea Buckthorn, #EB974E, (235, 151, 78)'},
        {id: 2, name: "Plum", backgroundColor: "#913D88", hexcode: '#913D88', rgb: '(145, 61, 136)', category: 'Purple', full: 'Plum, #913D88, (145, 61, 136)', color: '#FFFFFF'},
        {id: 3, name: "Seance", backgroundColor: "#9A12B3", hexcode: '#9A12B3', rgb: '(154, 18, 179)', category: 'Purple', full: 'Seance, #9A12B3, (154, 18, 179)', color: '#FFFFFF'},
        {id: 4, name: "Medium Purple", backgroundColor: "#9370DB", hexcode: '#9370DB', rgb: '(147, 112, 219)', category: 'Purple', full: 'Medium Purple, #9370DB, (147, 112, 219)', color: '#FFFFFF'},
        {id: 5, name: "Light Wisteria", backgroundColor: "#BE90D4", hexcode: '#BE90D4', rgb: '(190, 144, 212)', category: 'Purple', full: 'Light Wisteria, #BE90D4, (190, 144, 212)'},
        {id: 6, name: "Studio", backgroundColor: "#8E44AD", hexcode: '#8E44AD', rgb: '(142, 68, 173)', category: 'Purple', full: 'Studio, #8E44AD, (142, 68, 173)', color: '#FFFFFF'},
        {id: 7, name: "Wisteria", backgroundColor: "#9B59B6", hexcode: '#9B59B6', rgb: '(155, 89, 182)', category: 'Purple', full: 'Wisteria, #9B59B6, (155, 89, 182)', color: '#FFFFFF'},
        {id: 8, name: "New York Pink", backgroundColor: "#E08283", hexcode: '#E08283', rgb: '(224, 130, 131)', category: 'Pink', full: 'New York Pink, #E08283, (224, 130, 131)'},
        {id: 9, name: "Pomegranate", backgroundColor: "#F22613", hexcode: '#F22613', rgb: '(242, 38, 19)', category: 'Red', full: 'Pomegranate, #F22613, (242, 38, 19)', color: '#FFFFFF'},
        {id: 10, name: "Sunglo", backgroundColor: "#E26A6A", hexcode: '#E26A6A', rgb: '(226, 106, 106)', category: 'Pink', full: 'Sunglo, #E26A6A, (226, 106, 106)'},
        {id: 11, name: "Old Brick", backgroundColor: "#96281B", hexcode: '#96281B', rgb: '(150, 40, 27)', category: 'Red', full: 'Old Brick, #96281B, (150, 40, 27)', color: '#FFFFFF'},
        {id: 12, name: "Flamingo", backgroundColor: "#EF4836", hexcode: '#EF4836', rgb: '(239, 72, 54)', category: 'Red', full: 'Flamingo, #EF4836, (239, 72, 54)'},
        {id: 13, name: "Valencia", backgroundColor: "#D64541", hexcode: '#D64541', rgb: '(214, 69, 65)', category: 'Red', full: 'Valencia, #D64541, (214, 69, 65)'},
        {id: 14, name: "Tall Poppy", backgroundColor: "#C0392B", hexcode: '#C0392B', rgb: '(192, 57, 43)', category: 'Red', full: 'Tall Poppy, #C0392B, (192, 57, 43)', color: '#FFFFFF'},
        {id: 15, name: "Monza", backgroundColor: "#CF000F", hexcode: '#CF000F', rgb: '(207, 0, 15)', category: 'Red', full: 'Monza, #CF000F, (207, 0, 15)', color: '#FFFFFF'},
        {id: 16, name: "Cinnabar", backgroundColor: "#E74C3C", hexcode: '#E74C3C', rgb: '(231, 76, 60)', category: 'Red', full: 'Cinnabar, #E74C3C, (231, 76, 60)'},
        {id: 17, name: "Tahiti Gold", backgroundColor: "#E87E04", hexcode: '#E87E04', rgb: '(232, 126, 4)', category: 'Orange', full: 'Tahiti Gold, #E87E04, (232, 126, 4)'},
        {id: 18, name: "Casablanca", backgroundColor: "#F4B350", hexcode: '#F4B350', rgb: '(244, 179, 80)', category: 'Orange', full: 'Casablanca, #F4B350, (244, 179, 80)'},
        {id: 19, name: "Crusta", backgroundColor: "#F2784B", hexcode: '#F2784B', rgb: '(242, 120, 75)', category: 'Orange', full: 'Crusta, #F2784B, (242, 120, 75)'},
        {id: 20, name: "Jaffa", backgroundColor: "#EB974E", hexcode: '#EB974E', rgb: '(235, 151, 78)', category: 'Orange', full: 'Jaffa, #EB974E, (235, 151, 78)'},
        {id: 21, name: "Saffron", color: "#444", opacityColor: '0.7', backgroundColor: "#F4D03F", hexcode: '#F4D03F', rgb: '(244, 208, 63)', category: 'Yellow', full: 'Saffron, #F4D03F, (244, 208, 63)'},
        {id: 22, name: "Burnt Orange", backgroundColor: "#D35400", hexcode: '#D35400', rgb: '(211, 84, 0)', category: 'Orange', full: 'Burnt Orange, #D35400, (211, 84, 0)', color: '#FFFFFF'},
        {id: 23, name: "Chambray", backgroundColor: "#3A539B", hexcode: '#3A539B', rgb: '(58, 83, 155)', category: 'Blue', full: 'Chambray, #3A539B, (58, 83, 155)', color: '#FFFFFF'},
        {id: 24, name: "Malibu", backgroundColor: "#6BB9F0", hexcode: '#6BB9F0', rgb: '(107, 185, 240)', category: 'Blue', full: 'Malibu, #6BB9F0, (107, 185, 240)'},
        {id: 25, name: "Dodger Blue", backgroundColor: "#00BFFF", hexcode: '#00BFFF', rgb: '(0, 191, 255)', category: 'Blue', full: 'Dodger Blue, #00BFFF, (0, 191, 255)'},
        {id: 26, name: "Ebony Clay", backgroundColor: "#22313F", hexcode: '#22313F', rgb: '(34, 49, 63)', category: 'Blue', full: 'Ebony Clay, #22313F, (34, 49, 63)', color: '#FFFFFF'},
        {id: 27, name: "Shakespeare", backgroundColor: "#52B3D9", hexcode: '#52B3D9', rgb: '(82, 179, 217)', category: 'Blue', full: 'Shakespeare, #52B3D9, (82, 179, 217)'},
        {id: 28, name: "Madison", backgroundColor: "#2C3E50", hexcode: '#2C3E50', rgb: '(44, 62, 80)', category: 'Blue', full: 'Madison, #2C3E50, (44, 62, 80)', color: '#FFFFFF'},
        {id: 29, name: "Salem", backgroundColor: "#1E824C", hexcode: '#1E824C', rgb: '(30, 130, 76)', category: 'Green', full: 'Salem, #1E824C, (30, 130, 76)', color: '#FFFFFF'},
        {id: 30, name: "Jade", backgroundColor: "#BE90D4", hexcode: '#BE90D4', rgb: '(0, 177, 106)', category: 'Purple', full: 'Jade, #BE90D4, (0, 177, 106)'},
        {id: 31, name: "Ocean Green", backgroundColor: "#4DAF7C", hexcode: '#4DAF7C', rgb: '(77, 175, 124)', category: 'Green', full: 'Ocean Green, #4DAF7C, (77, 175, 124)'},
        {id: 32, name: "Jungle Green", backgroundColor: "#26C281", hexcode: '#26C281', rgb: '(38, 194, 129)', category: 'Green', full: 'Jungle Green, #26C281, (38, 194, 129)'},
        {id: 33, name: "Green Haze", backgroundColor: "#019875", hexcode: '#019875', rgb: '(1, 152, 117)', category: 'Green', full: 'Green Haze, #019875, (1, 152, 117)'},
        {id: 34, name: "Emerald", backgroundColor: "#3FC380", hexcode: '#3FC380', rgb: '(63, 195, 128)', category: 'Green', full: 'Emerald, #3FC380, (63, 195, 128)'},
        {id: 35, name: "Shamrock", backgroundColor: "#2ECC71", hexcode: '#2ECC71', rgb: '(46, 204, 113)', category: 'Green', full: 'Shamrock, #2ECC71, (46, 204, 113)'},
        {id: 36, name: "Riptide", backgroundColor: "#86E2D5", hexcode: '#86E2D5', rgb: '(134, 226, 213)', category: 'Green', full: 'Riptide, #86E2D5, (134, 226, 213)'},
        {id: 37, name: "Observatory", backgroundColor: "#049372", hexcode: '#049372', rgb: '(4, 147, 114)', category: 'Green', full: 'Observatory, #049372, (4, 147, 114)'},
        {id: 38, name: "Downy", backgroundColor: "#65C6BB", hexcode: '#65C6BB', rgb: '(101, 198, 187)', category: 'Green', full: 'Downy, #65C6BB, (101, 198, 187)'},
        {id: 39, name: "Fountain Blue", backgroundColor: "#5C97BF", hexcode: '#5C97BF', rgb: '(92, 151, 191)', category: 'Blue', full: 'Fountain Blue, #5C97BF, (92, 151, 191)'},
        {id: 40, name: "Steel Blue", backgroundColor: "#4682B4", hexcode: '#4682B4', rgb: '(70, 130, 180)', category: 'Blue', full: 'Steel Blue, #4682B4, (70, 130, 180)', color: '#FFFFFF'},
        {id: 41, name: "Fire Bush", backgroundColor: "#EB9532", hexcode: '#EB9532', rgb: '(235, 149, 50)', category: 'Orange', full: 'Fire Bush, #EB9532, (235, 149, 50)'},
        {id: 42, name: "Sunset Orange", backgroundColor: "#F64747", hexcode: '#F64747', rgb: '(246, 71, 71)', category: 'Pink', full: 'Sunset Orange, #F64747, (246, 71, 71)', color: '#FFFFFF'},
        {id: 43, name: "Razzmatazz", backgroundColor: "#DB0A5B", hexcode: '#DB0A5B', rgb: '(219, 10, 91)', category: 'Pink', full: 'Razzmatazz, #DB0A5B, (219, 10, 91)', color: '#FFFFFF'},
        {id: 44, name: "Gossip", backgroundColor: "#87D37C", hexcode: '#87D37C', rgb: '(135, 211, 124)', category: 'Green', full: 'Gossip, #87D37C, (135, 211, 124)'},
        {id: 45, name: "Fresh Onion", backgroundColor: "#5B8930", hexcode: '#5B8930', rgb: '(91, 137, 48)', category: 'Green', full: 'Fresh Onion, #5B8930, (91, 137, 48)'},
        {id: 46, name: "Chestnut Rose", backgroundColor: "#D24D57", hexcode: '#D24D57', rgb: '(210, 77, 87)', category: 'Red', full: 'Chestnut Rose, #D24D57, (210, 77, 87)'},
        {id: 47, name: "Wax Flower", backgroundColor: "#F1A9A0", hexcode: '#F1A9A0', rgb: '(241, 169, 160)', category: 'Pink', full: 'Wax Flower, #F1A9A0, (241, 169, 160)'},
        {id: 48, name: "Cabaret", backgroundColor: "#D2527F", hexcode: '#D2527F', rgb: '(210, 82, 127)', category: 'Pink', full: 'Cabaret, #D2527F, (210, 82, 127)', color: '#FFFFFF'},
        {id: 49, name: "California", backgroundColor: "#F89406", hexcode: '#F89406', rgb: '(248, 148, 6)', category: 'Orange', full: 'California, #F89406, (248, 148, 6)'},
        {id: 50, name: "Aqua Island", backgroundColor: "#A2DED0", hexcode: '#A2DED0', rgb: '(162, 222, 208)', category: 'Green', full: 'Aqua Island, #A2DED0, (162, 222, 208)'},
        {id: 51, name: "Jelly Bean", backgroundColor: "#2574A9", hexcode: '#2574A9', rgb: '(37, 116, 169)', category: 'Blue', full: 'Jelly Bean, #2574A9, (37, 116, 169)', color: '#FFFFFF'},
        {id: 52, name: "Hoki", backgroundColor: "#67809F", hexcode: '#67809F', rgb: '(103, 128, 159)', category: 'Blue', full: 'Hoki, #67809F, (103, 128, 159)'},
        {id: 53, name: "Jordy Blue", backgroundColor: "#89C4F4", hexcode: '#89C4F4', rgb: '(137, 196, 244)', category: 'Blue', full: 'Jordy Blue, #89C4F4, (137, 196, 244)'},
        {id: 54, name: "Picton Blue", backgroundColor: "#59ABE3", hexcode: '#59ABE3', rgb: '(89, 171, 227)', category: 'Blue', full: 'Picton Blue, #59ABE3, (89, 171, 227)'},
        {id: 55, name: "Madang", backgroundColor: "#C8F7C5", hexcode: '#C8F7C5', rgb: '(200, 247, 197)', category: 'Green', full: 'Madang, #C8F7C5, (200, 247, 197)'},
        {id: 56, name: "Turquoise", backgroundColor: "#40E0D0", hexcode: '#40E0D0', rgb: '(64, 224, 208)', category: 'Green', full: 'Turquoise, #40E0D0, (64, 224, 208)'},
        {id: 57, name: "Light Sea Green", backgroundColor: "#1BA39C", hexcode: '#1BA39C', rgb: '(27, 163, 156)', category: 'Green', full: 'Light Sea Green, #1BA39C, (27, 163, 156)'},
        {id: 58, name: "Zest", backgroundColor: "#E67E22", hexcode: '#E67E22', rgb: '(230, 126, 34)', category: 'Orange', full: 'Zest, #E67E22, (230, 126, 34)'},
        {id: 59, name: "Ripe Lemon", backgroundColor: "#F7CA18", hexcode: '#F7CA18', rgb: '(247, 202, 24)', category: 'Yellow', full: 'Ripe Lemon, #F7CA18, (247, 202, 24)'},
        {id: 60, name: "Ecstasy", backgroundColor: "#F9690E", hexcode: '#F9690E', rgb: '(249, 105, 14)', category: 'Orange', full: 'Ecstasy, #F9690E, (249, 105, 14)'},
        {id: 61, name: "Buttercup", backgroundColor: "#F39C12", hexcode: '#F39C12', rgb: '(243, 156, 18)', category: 'Orange', full: 'Buttercup, #F39C12, (243, 156, 18)'},
        {id: 62, name: "Cream Can", backgroundColor: "#F5D76E", hexcode: '#F5D76E', rgb: '(245, 215, 110)', category: 'Yellow', full: 'Cream Can, #F5D76E, (245, 215, 110)'},
        {id: 63, name: "Radical Red", backgroundColor: "#F62459", hexcode: '#F62459', rgb: '(246, 36, 89)', category: 'Pink', full: 'Radical Red, #F62459, (246, 36, 89)', color: '#FFFFFF'},
        {id: 64, name: "San Marino", backgroundColor: "#446CB3", hexcode: '#446CB3', rgb: '(68, 108, 179)', category: 'Blue', full: 'San Marino, #446CB3, (68, 108, 179)', color: '#FFFFFF'},
        {id: 65, name: "Ming", backgroundColor: "#336E7B", hexcode: '#336E7B', rgb: '(51, 110, 123)', category: 'Blue', full: 'Ming, #336E7B, (51, 110, 123)', color: '#FFFFFF'},
        {id: 66, name: "Caribbean Green", backgroundColor: "#03C9A9", hexcode: '#03C9A9', rgb: '(3, 201, 169)', category: 'Green', full: 'Caribbean Green, #03C9A9, (3, 201, 169)'},
        {id: 67, name: "Niagara", backgroundColor: "#16A085", hexcode: '#16A085', rgb: '(22, 160, 133)', category: 'Green', full: 'Niagara, #16A085, (22, 160, 133)', color: '#FFFFFF'},
        {id: 68, name: "Confetti", backgroundColor: "#E9D460", hexcode: '#E9D460', rgb: '(233, 212, 96)', category: 'Yellow', full: 'Confetti, #E9D460, (233, 212, 96)'},
        {id: 69, name: "Scarlet", backgroundColor: "#FD0E35", hexcode: '#FD0E35', rgb: '(253, 14, 53)', category: 'Red', full: 'Scarlet, #FD0E35, (253, 14, 53)', color: '#FFFFFF'},
        {id: 70, name: "Madder Lake", backgroundColor: "#CC3336", hexcode: '#CC3336', rgb: '(204, 51, 54)', category: 'Red', full: 'Madder Lake, #CC3336, (204, 51, 54)', color: '#FFFFFF'},
        {id: 72, name: "Wild Strawberry", backgroundColor: "#FF3399", hexcode: '#FF3399', rgb: '(255, 51, 153)', category: 'Pink', full: 'Wild Strawberry, #FF3399, (255, 51, 153)', color: '#FFFFFF'},
        {id: 73, name: "Melon", backgroundColor: "#FEBAAD", hexcode: '#FEBAAD', rgb: '(254, 186, 173)', category: 'Pink', full: 'Melon, #FEBAAD, (254, 186, 173)'},
        {id: 74, name: "Peach", backgroundColor: "#FFCBA4", hexcode: '#FFCBA4', rgb: '(255, 203, 164)', category: 'Orange', full: 'Peach, #FFCBA4, (255, 203, 164)'},
        {id: 75, name: "Soft Red", backgroundColor: "#EC644B", hexcode: '#EC644B', rgb: '(236, 100, 75)', category: 'Red', full: 'Soft Red, #EC644B, (236, 100, 75)'},
        {id: 76, name: "Thunderbird", backgroundColor: "#D91E18", hexcode: '#D91E18', rgb: '(217, 30, 24)', category: 'Red', full: 'Thunderbird, #D91E18, (217, 30, 24)'},
        {id: 77, name: "Bittersweet", backgroundColor: "#FE6F5E", hexcode: '#FE6F5E', rgb: '(254, 111, 94)', category: 'Orange', full: 'Bittersweet, #FE6F5E, (254, 111, 94)'},
        {id: 78, name: "Lightning Yellow", backgroundColor: "#F5AB35", hexcode: '#F5AB35', rgb: '(245, 171, 53)', category: 'Yellow', full: 'Lightning Yellow, #F5AB35, (245, 171, 53)'},
        {id: 80, name: "Summer Sky", backgroundColor: "#1E8BC3", hexcode: '#1E8BC3', rgb: '(30, 139, 195)', category: 'Blue', full: 'Summer Sky, #1E8BC3, (30, 139, 195)', color: '#FFFFFF'},
        {id: 81, name: "Spray", backgroundColor: "#81CFE0", hexcode: '#81CFE0', rgb: '(129, 207, 224)', category: 'Blue', full: 'Spray, #81CFE0, (129, 207, 224)'},
        {id: 82, name: "Humming Bird", backgroundColor: "#C5EFF7", hexcode: '#C5EFF7', rgb: '(197, 239, 247)', category: 'Blue', full: 'Humming Bird, #C5EFF7, (197, 239, 247)'},
        {id: 83, name: "Silver Tree", backgroundColor: "#68C3A3", hexcode: '#68C3A3', rgb: '(104, 195, 163)', category: 'Green', full: 'Silver Tree, #68C3A3, (104, 195, 163)'},
        {id: 84, name: "Medium Turquoise", backgroundColor: "#48D1CC", hexcode: '#48D1CC', rgb: '(72, 209, 204)', category: 'Blue', full: 'Medium Turquoise, #48D1CC, (72, 209, 204)'},
        {id: 85, name: "Eucalyptus", backgroundColor: "#26A65B", hexcode: '#26A65B', rgb: '(38, 166, 91)', category: 'Green', full: 'Eucalyptus, #913D88, (38, 166, 91)'},
        {id: 86, name: "Wistful", backgroundColor: "#AEA8D3", hexcode: '#AEA8D3', rgb: '(174, 168, 211)', category: 'Purple', full: 'Wistful, #AEA8D3, (174, 168, 211)'},
        {id: 87, name: "Snuff", backgroundColor: "#DCC6E0", hexcode: '#DCC6E0', rgb: '(220, 198, 224)', category: 'Purple', full: 'Snuff, #DCC6E0, (220, 198, 224)'},
        {id: 88, name: "Rebecca Purple", backgroundColor: "#663399", hexcode: '#663399', rgb: '(102, 51, 153)', category: 'Purple', full: 'Rebecca Purple, #663399, (102, 51, 153)', color: '#FFFFFF'},
        {id: 89, name: "Honey Flower", backgroundColor: "#674172", hexcode: '#674172', rgb: '(103, 65, 114)', category: 'Purple', full: 'Honey Flower, #674172, (103, 65, 114)', color: '#FFFFFF'},
        {id: 90, name: "Starbucks", backgroundColor: "#00704A", hexcode: '#00704A', rgb: '(0, 112, 74)', category: 'Brand, Green', full: 'Starbucks, #00704A, (0, 112, 74)', color: '#FFFFFF'},
        {id: 91, name: "Twitter", backgroundColor: "#1DA1F2", hexcode: '#1DA1F2', rgb: '(29, 161, 242)', category: 'Brand, Blue', full: 'Twitter, #1DA1F2, (29, 161, 242)'},
        {id: 92, name: "Google+", backgroundColor: "#DD4B39", hexcode: '#DD4B39', rgb: '(221, 75, 57)', category: 'Brand, Red', full: 'Google+, #DD4B39, (221, 75, 57)', color: '#FFFFFF'},
        {id: 93, name: "IBM", backgroundColor: "#006699", hexcode: '#006699', rgb: '(0, 102, 153)', category: 'Brand, Blue', full: 'IBM, #006699, (0, 102, 153)', color: '#FFFFFF'},
        {id: 94, name: "Facebook", backgroundColor: "#3B5998", hexcode: '#3B5998', rgb: '(59, 89, 152)', category: 'Brand, Blue', full: 'Facebook, #3B5998, (59, 89, 152)', color: '#FFFFFF'},
        {id: 95, name: "Snapchat", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FFFC00", hexcode: '#FFFC00', rgb: '(255, 252, 0)', category: 'Brand, Yellow', full: 'Snapchat, #FFFC00, (255, 252, 0)', color: '#000000'},
        {id: 96, name: "Medium", backgroundColor: "#00AB6C", hexcode: '#00AB6C', rgb: '(0, 171, 108)', category: 'Brand, Green', full: 'Medium, #00AB6C, (0, 171, 108)'},
        {id: 97, name: "Pinterest", backgroundColor: "#BD081C", hexcode: '#BD081C', rgb: '(189, 8, 28)', category: 'Brand, Red', full: 'Pinterest, #BD081C, (189, 8, 28)', color: '#FFFFFF'},
        {id: 98, name: "LinkedIn", backgroundColor: "#0077B5", hexcode: '#0077B5', rgb: '(0, 119, 181)', category: 'Brand, Blue', full: 'LinkedIn, #0077B5, (0, 119, 181)', color: '#FFFFFF'},
        {id: 99, name: "Vine", backgroundColor: "#00BF8F", hexcode: '#00BF8F', rgb: '(0, 191, 143)', category: 'Brand, Green', full: 'Vine, #00BF8F, (0, 191, 143)'},
        {id: 100, name: "Vimeo", backgroundColor: "#1AB7EA", hexcode: '#1AB7EA', rgb: '(26, 183, 234)', category: 'Brand, Blue', full: 'Vimeo, #1AB7EA, (26, 183, 234)'},
        {id: 101, name: "Tumblr", backgroundColor: "#35465C", hexcode: '#35465C', rgb: '(53, 70, 92)', category: 'Brand, Blue', full: 'Tumblr, #35465C, (53, 70, 92)', color: '#FFFFFF'},
        {id: 102, name: "Dribbble", backgroundColor: "#EA4C89", hexcode: '#EA4C89', rgb: '(234, 76, 137)', category: 'Brand, Pink', full: 'Dribbble, #EA4C89, (234, 76, 137)', color: '#FFFFFF'},
        {id: 103, name: "Twitch", backgroundColor: "#6441A5", hexcode: '#6441A5', rgb: '(100, 65, 165)', category: 'Brand, Purple', full: 'Twitch, #6441A5, (100, 65, 165)', color: '#FFFFFF'},
        {id: 104, name: "Foursquare", backgroundColor: "#F94877", hexcode: '#F94877', rgb: '(249, 72, 119)', category: 'Brand, Pink', full: 'Foursquare, #F94877, (249, 72, 119)', color: '#FFFFFF'},
        {id: 105, name: "Chocolate", backgroundColor: "#D2691E", hexcode: '#D2691E', rgb: '(210, 105, 30)', category: 'Brown', full: 'Chocolate, #D2691E, (210, 105, 30)', color: '#FFFFFF'},
        {id: 106, name: "Dell", backgroundColor: "#0085C3", hexcode: '#0085C3', rgb: '(0, 133, 195)', category: 'Blue, Brand', full: 'Dell, #0085C3, (0, 133, 195)'},
        {id: 107, name: "Android", backgroundColor: "#A4C639", hexcode: '#A4C639', rgb: '(164, 198, 57)', category: 'Green, Brand', full: 'Android, #A4C639, (164, 198, 57)'},
        {id: 108, name: "McDonald's", backgroundColor: "#FFCC00", hexcode: '#FFCC00', rgb: '(255, 204, 0)', category: 'Yellow, Brand', full: 'McDonalds, #FFCC00, (255, 204, 0)'},
        {id: 109, name: "The Home Depot", backgroundColor: "#F58220", hexcode: '#F58220', rgb: '(245, 130, 32)', category: 'Orange, Brand', full: 'The Home Depot, #F58220, (245, 130, 32)'},
        {id: 110, name: "Xbox", backgroundColor: "#107C10", hexcode: '#107C10', rgb: '(16, 124, 16)', category: 'Green, Brand', full: 'Xbox, #107C10, (16, 124, 16)', color: '#FFFFFF'},
        {id: 111, name: "Maroon", backgroundColor: "#800000", hexcode: '#800000', rgb: '(128, 0, 0)', category: 'Maroon', full: 'Maroon, #800000, (128, 0, 0)', color: '#FFFFFF'},
        {id: 112, name: "Sienna", backgroundColor: "#A0522D", hexcode: '#A0522D', rgb: '(160, 82, 45)', category: 'Brown', full: 'Sienna, #A0522D, (160, 82, 45)', color: '#FFFFFF'},
        {id: 113, name: "Brown", backgroundColor: "#A52A2A", hexcode: '#A52A2A', rgb: '(165, 42, 42)', category: 'Brown', full: 'Brown, #A52A2A, (165, 42, 42)', color: '#FFFFFF'},
        {id: 114, name: "Saddle Brown", backgroundColor: "#8B4513", hexcode: '#8B4513', rgb: '(139, 69, 19)', category: 'Brown', full: 'Saddle Brown, #8B4513, (139, 69, 19)', color: '#FFFFFF'},
        {id: 115, name: "Peru", backgroundColor: "#CD853F", hexcode: '#CD853F', rgb: '(205, 133, 63)', category: 'Brown', full: 'Peru, #CD853F, (205, 133, 63)'},
        {id: 116, name: "Golden Rod", backgroundColor: "#DAA520", hexcode: '#DAA520', rgb: '(218, 165, 32)', category: 'Brown', full: 'Golden Rod, #DAA520, (218, 165, 32)'},
        {id: 117, name: "Dark Golden Rod", backgroundColor: "#B8860B", hexcode: '#B8860B', rgb: '(184, 134, 11)', category: 'Brown', full: 'Dark Golden Rod, #B8860B, (184, 134, 11)'},
        {id: 118, name: "Sandy Brown", backgroundColor: "#F4A460", hexcode: '#F4A460', rgb: '(244, 164, 96)', category: 'Brown', full: 'Sandy Brown, #F4A460, (244, 164, 96)'},
        {id: 119, name: "Rosy Brown", backgroundColor: "#BC8F8F", hexcode: '#BC8F8F', rgb: '(188, 143, 143)', category: 'Brown', full: 'Rosy Brown, #BC8F8F, (188, 143, 143)'},
        {id: 120, name: "Tan", backgroundColor: "#D2B48C", hexcode: '#D2B48C', rgb: '(210, 180, 140)', category: 'Brown', full: 'Tan, #D2B48C, (210, 180, 140)'},
        {id: 121, name: "Burly Wood", backgroundColor: "#DEB887", hexcode: '#DEB887', rgb: '(222, 184, 135)', category: 'Brown', full: 'Burly Wood, #DEB887, (222, 184, 135)'},
        {id: 122, name: "Wheat", backgroundColor: "#F5DEB3", hexcode: '#F5DEB3', rgb: '(245, 222, 179)', category: 'Brown', full: 'Wheat, #F5DEB3, (245, 222, 179)'},
        {id: 123, name: "Navajo White", backgroundColor: "#FFDEAD", hexcode: '#FFDEAD', rgb: '(255, 222, 173)', category: 'Brown', full: 'Navajo White, #FFDEAD, (255, 222, 173)', color: '#000000'},
        {id: 124, name: "Bisque", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FFE4C4", hexcode: '#FFE4C4', rgb: '(255, 228, 196)', category: 'Brown', full: 'Bisque, #FFE4C4, (255, 228, 196)', color: '#000000'},
        {id: 125, name: "Blanched Almond", backgroundColor: "#FFEBCD", hexcode: '#FFEBCD', rgb: '(255, 235, 205)', category: 'Brown', full: 'Blanched Almond, #FFEBCD, (255, 235, 205)'},
        {id: 126, name: "Corn Silk", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FFF8DC", hexcode: '#FFF8DC', rgb: '(255, 248, 220)', category: 'Brown', full: 'Corn Silk, #FFF8DC, (255, 248, 220)', color: '#000000'},
        {id: 127, name: "Light Salmon", backgroundColor: "#FFA07A", hexcode: '#FFA07A', rgb: '(255, 160, 122)', category: 'Orange, Red', full: 'Light Salmon, #FFA07A, (255, 160, 122)'},
        {id: 128, name: "Coral", backgroundColor: "#FF7F50", hexcode: '#FF7F50', rgb: '(255, 127, 80)', category: 'Orange', full: 'Coral, #FF7F50, (255, 127, 80)'},
        {id: 129, name: "Tomato", backgroundColor: "#FF6347", hexcode: '#FF6347', rgb: '(255, 99, 71)', category: 'Orange', full: 'Tomato, #FF6347, (255, 99, 71)'},
        {id: 130, name: "Orange Red", backgroundColor: "#FF4500", hexcode: '#FF4500', rgb: '(255, 69, 0)', category: 'Orange', full: 'Orange Red, #FF4500, (255, 69, 0)', color: '#FFFFFF'},
        {id: 131, name: "Dark Orange", backgroundColor: "#FF8C00", hexcode: '#FF8C00', rgb: '(255, 140, 0)', category: 'Orange', full: 'Dark Orange, #FF8C00, (255, 140, 0)'},
        {id: 132, name: "Orange", backgroundColor: "#FFA500", hexcode: '#FFA500', rgb: '(255, 165, 0)', category: 'Orange', full: 'Orange, #FFA500, (255, 165, 0)'},
        {id: 133, name: "Indian Red", backgroundColor: "#CD5C5C", hexcode: '#CD5C5C', rgb: '(205, 92, 92)', category: 'Red', full: 'Indian Red, #CD5C5C, (205, 92, 92)'},
        {id: 134, name: "Light Coral", backgroundColor: "#F08080", hexcode: '#F08080', rgb: '(240, 128, 128)', category: 'Red', full: 'Light Coral, #F08080, (240, 128, 128)'},
        {id: 135, name: "Salmon", backgroundColor: "#FA8072", hexcode: '#FA8072', rgb: '(250, 128, 114)', category: 'Red', full: 'Salmon, #FA8072, (250, 128, 114)'},
        {id: 136, name: "Dark Salmon", backgroundColor: "#E9967A", hexcode: '#E9967A', rgb: '(233, 150, 122)', category: 'Red', full: 'Dark Salmon, #E9967A, (233, 150, 122)'},
        {id: 137, name: "Crimson", backgroundColor: "#DC143C", hexcode: '#DC143C', rgb: '(220, 20, 60)', category: 'Red', full: 'Crimson, #DC143C, (220, 20, 60)', color: '#FFFFFF'},
        {id: 138, name: "Fire Brick", backgroundColor: "#B22222", hexcode: '#B22222', rgb: '(178, 34, 34)', category: 'Red', full: 'Fire Brick, #B22222, (178, 34, 34)', color: '#FFFFFF'},
        {id: 139, name: "Dark Red", backgroundColor: "#8B0000", hexcode: '#8B0000', rgb: '(139, 0, 0)', category: 'Red', full: 'Dark Red, #8B0000, (139, 0, 0)', color: '#FFFFFF'},
        {id: 140, name: "Light Pink", backgroundColor: "#FFB6C1", hexcode: '#FFB6C1', rgb: '(255, 182, 193)', category: 'Pink', full: 'Light Pink, #FFB6C1, (255, 182, 193)'},
        {id: 141, name: "Hot Pink", backgroundColor: "#FF69B4", hexcode: '#FF69B4', rgb: '(255, 105, 180)', category: 'Pink', full: 'Hot Pink, #FF69B4, (255, 105, 180)'},
        {id: 142, name: "Deep Pink", backgroundColor: "#FF1493", hexcode: '#FF1493', rgb: '(255, 20, 147)', category: 'Pink', full: 'Hot Pink, #FF1493, (255, 20, 147)', color: '#FFFFFF'},
        {id: 143, name: "Medium Violet Red", backgroundColor: "#C71585", hexcode: '#C71585', rgb: '(199, 21, 133)', category: 'Pink', full: 'Medium Violet Red, #C71585, (199, 21, 133)', color: '#FFFFFF'},
        {id: 144, name: "Pale Violet Red", backgroundColor: "#DB7093", hexcode: '#DB7093', rgb: '(219, 112, 147)', category: 'Pink', full: 'Pale Violet Red, #DB7093, (219, 112, 147)'},
        {id: 145, name: "Lavender", backgroundColor: "#E6E6FA", hexcode: '#E6E6FA', rgb: '(230, 230, 250)', category: 'Purple', full: 'Lavender, #E6E6FA, (230, 230, 250)'},
        {id: 146, name: "Thistle", backgroundColor: "#D8BFD8", hexcode: '#D8BFD8', rgb: '(216, 191, 216)', category: 'Purple', full: 'Thistle, #D8BFD8, (216, 191, 216)'},
        {id: 147, name: "Violet", backgroundColor: "#EE82EE", hexcode: '#EE82EE', rgb: '(238, 130, 238)', category: 'Purple', full: 'Violet, #EE82EE, (238, 130, 238)'},
        {id: 148, name: "Orchid", backgroundColor: "#DA70D6", hexcode: '#DA70D6', rgb: '(218, 112, 214)', category: 'Purple', full: 'Orchid, #DA70D6, (218, 112, 214)'},
        {id: 149, name: "Fuchsia", backgroundColor: "#FF00FF", hexcode: '#FF00FF', rgb: '(255, 0, 255)', category: 'Purple', full: 'Fuchsia, #FF00FF, (255, 0, 255)', color: '#FFFFFF'},
        {id: 150, name: "Magenta", backgroundColor: "#FF00FF", hexcode: '#FF00FF', rgb: '(255, 0, 255)', category: 'Purple', full: 'Magenta, #FF00FF, (255, 0, 255)', color: '#FFFFFF'},
        {id: 151, name: "Medium Orchid", backgroundColor: "#BA55D3", hexcode: '#BA55D3', rgb: '(186, 85, 211)', category: 'Purple', full: 'Medium Orchid, #BA55D3, (186, 85, 211)', color: '#FFFFFF'},
        {id: 152, name: "Blue Violet", backgroundColor: "#8A2BE2", hexcode: '#8A2BE2', rgb: '(138, 43, 226)', category: 'Purple', full: 'Blue Violet, #8A2BE2, (138, 43, 226)', color: '#FFFFFF'},
        {id: 153, name: "Dark Violet", backgroundColor: "#9400D3", hexcode: '#9400D3', rgb: '(148, 0, 211)', category: 'Purple', full: 'Dark Violet, #9400D3, (148, 0, 211)', color: '#FFFFFF'},
        {id: 154, name: "Dark Orchid", backgroundColor: "#9932CC", hexcode: '#9932CC', rgb: '(153, 50, 204)', category: 'Purple', full: 'Dark Orchid, #9932CC, (153, 50, 204)', color: '#FFFFFF'},
        {id: 155, name: "Dark Magenta", backgroundColor: "#8B008B", hexcode: '#8B008B', rgb: '(139, 0, 139)', category: 'Purple', full: 'Dark Magenta, #8B008B, (139, 0, 139)', color: '#FFFFFF'},
        {id: 156, name: "Indigo", backgroundColor: "#4B0082", hexcode: '#4B0082', rgb: '(75, 0, 130)', category: 'Purple', full: 'Indigo, #4B0082, (75, 0, 130)', color: '#FFFFFF'},
        {id: 157, name: "Slate Blue", backgroundColor: "#6A5ACD", hexcode: '#6A5ACD', rgb: '(106, 90, 205)', category: 'Purple', full: 'Slate Blue, #6A5ACD, (106, 90, 205)', color: '#FFFFFF'},
        {id: 158, name: "Dark Slate Blue", backgroundColor: "#483D8B", hexcode: '#483D8B', rgb: '(72, 61, 139)', category: 'Purple', full: 'Dark Slate Blue, #483D8B, (72, 61, 139)', color: '#FFFFFF'},
        {id: 159, name: "Medium Slate Blue", backgroundColor: "#7B68EE", hexcode: '#7B68EE', rgb: '(123, 104, 238)', category: 'Purple', full: 'Medium Slate Blue, #7B68EE, (123, 104, 238)', color: '#FFFFFF'},
        {id: 160, name: "Green Yellow", backgroundColor: "#ADFF2F", hexcode: '#ADFF2F', rgb: '(173, 255, 47)', category: 'Green', full: 'Green Yellow, #ADFF2F, (173, 255, 47)'},
        {id: 161, name: "Chartreuse", backgroundColor: "#7FFF00", hexcode: '#7FFF00', rgb: '(127, 255, 0)', category: 'Green', full: 'Chartreuse, #7FFF00, (127, 255, 0)'},
        {id: 162, name: "Lawn Green", backgroundColor: "#7CFC00", hexcode: '#7CFC00', rgb: '(124, 252, 0)', category: 'Green', full: 'Lawn Green, #7CFC00, (124, 252, 0)'},
        {id: 163, name: "Lime", backgroundColor: "#00FF00", hexcode: '#00FF00', rgb: '(0, 255, 0)', category: 'Green', full: 'Lime, #00FF00, (0, 255, 0)'},
        {id: 164, name: "Lime Green", backgroundColor: "#32CD32", hexcode: '#32CD32', rgb: '(50, 205, 50)', category: 'Green', full: 'Lime Green, #32CD32, (50, 205, 50)'},
        {id: 165, name: "Pale Green", backgroundColor: "#98FB98", hexcode: '#98FB98', rgb: '(152, 251, 152)', category: 'Green', full: 'Pale Green, #98FB98, (152, 251, 152)'},
        {id: 166, name: "Light Green", backgroundColor: "#90EE90", hexcode: '#90EE90', rgb: '(144, 238, 144)', category: 'Green', full: 'Light Green, #90EE90, (144, 238, 144)'},
        {id: 167, name: "Medium Spring Green", backgroundColor: "#00FA9A", hexcode: '#00FA9A', rgb: '(0, 250, 154)', category: 'Green', full: 'Medium Spring Green, #00FA9A, (0, 250, 154)'},
        {id: 168, name: "Spring Green", backgroundColor: "#00FF7F", hexcode: '#00FF7F', rgb: '(0, 255, 127)', category: 'Green', full: 'Spring Green, #00FF7F, (0, 255, 127)'},
        {id: 169, name: "Medium Sea Green", backgroundColor: "#3CB371", hexcode: '#3CB371', rgb: '(60, 179, 113)', category: 'Green', full: 'Medium Sea Green, #3CB371, (60, 179, 113)'},
        {id: 170, name: "Sea Green", backgroundColor: "#2E8B57", hexcode: '#2E8B57', rgb: '(46, 139, 87)', category: 'Green', full: 'Sea Green, #2E8B57, (46, 139, 87)', color: '#FFFFFF'},
        {id: 171, name: "Forest Green", backgroundColor: "#228B22", hexcode: '#228B22', rgb: '(34, 139, 34)', category: 'Green', full: 'Forest Green, #228B22, (34, 139, 34)', color: '#FFFFFF'},
        {id: 172, name: "Dark Green", backgroundColor: "#006400", hexcode: '#006400', rgb: '(0, 100, 0)', category: 'Green', full: 'Dark Green, #006400, (0, 100, 0)', color: '#FFFFFF'},
        {id: 173, name: "Yellow Green", backgroundColor: "#9ACD32", hexcode: '#9ACD32', rgb: '(154, 205, 50)', category: 'Green', full: 'Yellow Green, #9ACD32, (154, 205, 50)'},
        {id: 174, name: "Olive Drab", backgroundColor: "#6B8E23", hexcode: '#6B8E23', rgb: '(107, 142, 35)', category: 'Green', full: 'Olive Drab, #6B8E23, (107, 142, 35)', color: '#FFFFFF'},
        {id: 175, name: "Olive", backgroundColor: "#808000", hexcode: '#808000', rgb: '(128, 128, 0)', category: 'Green', full: 'Olive, #808000, (128, 128, 0)', color: '#FFFFFF'},
        {id: 176, name: "Dark Olive Green", backgroundColor: "#556B2F", hexcode: '#556B2F', rgb: '(85, 107, 47)', category: 'Green', full: 'Dark Olive Green, #556B2F, (85, 107, 47)', color: '#FFFFFF'},
        {id: 177, name: "Medium Aqua Marine", backgroundColor: "#66CDAA", hexcode: '#66CDAA', rgb: '(102, 205, 170)', category: 'Green', full: 'Medium Aqua Marine, #66CDAA, (102, 205, 170)'},
        {id: 178, name: "Dark Sea Green", backgroundColor: "#8FBC8F", hexcode: '#8FBC8F', rgb: '(143, 188, 143)', category: 'Green', full: 'Dark Sea Green, #8FBC8F, (143, 188, 143)'},
        {id: 179, name: "Light Sea Green", backgroundColor: "#20B2AA", hexcode: '#20B2AA', rgb: '(32, 178, 170)', category: 'Green', full: 'Light Sea Green, #20B2AA, (32, 178, 170)'},
        {id: 180, name: "Dark Cyan", backgroundColor: "#008B8B", hexcode: '#008B8B', rgb: '(0, 139, 139)', category: 'Green', full: 'Dark Cyan, #008B8B, (0, 139, 139)', color: '#FFFFFF'},
        {id: 181, name: "Teal", backgroundColor: "#008080", hexcode: '#008080', rgb: '(0, 128, 128)', category: 'Green', full: 'Teal, #008080, (0, 128, 128)', color: '#FFFFFF'},
        {id: 182, name: "Aqua", backgroundColor: "#00FFFF", hexcode: '#00FFFF', rgb: '(0, 255, 255)', category: 'Blue', full: 'Aqua, #00FFFF, (0, 255, 255)'},
        {id: 183, name: "Cyan", backgroundColor: "#00FFFF", hexcode: '#00FFFF', rgb: '(0, 255, 255)', category: 'Blue', full: 'Cyan, #00FFFF, (0, 255, 255)'},
        {id: 184, name: "Light Cyan", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#E0FFFF", hexcode: '#E0FFFF', rgb: '(224, 255, 255)', category: 'Blue', full: 'Light Cyan, #E0FFFF, (224, 255, 255)', color: '#000000'},
        {id: 185, name: "Pale Turquoise", backgroundColor: "#AFEEEE", hexcode: '#AFEEEE', rgb: '(175, 238, 238)', category: 'Blue', full: 'Pale Turquoise, #AFEEEE, (175, 238, 238)'},
        {id: 186, name: "Aquamarine", backgroundColor: "#7FFFD4", hexcode: '#7FFFD4', rgb: '(127, 255, 212)', category: 'Blue', full: 'Aquamarine, #7FFFD4, (127, 255, 212)'},
        {id: 187, name: "Dark Turquoise", backgroundColor: "#00CED1", hexcode: '#00CED1', rgb: '(0, 206, 209)', category: 'Blue', full: 'Dark Turquoise, #00CED1, (0, 206, 209)'},
        {id: 188, name: "Cadet Blue", backgroundColor: "#5F9EA0", hexcode: '#5F9EA0', rgb: '(95, 158, 160)', category: 'Blue', full: 'Cadet Blue, #5F9EA0, (95, 158, 160)'},
        {id: 189, name: "Light Steel Blue", backgroundColor: "#B0C4DE", hexcode: '#B0C4DE', rgb: '(176, 196, 222)', category: 'Blue', full: 'Light Steel Blue, #B0C4DE, (176, 196, 222)'},
        {id: 190, name: "Powder Blue", backgroundColor: "#B0E0E6", hexcode: '#B0E0E6', rgb: '(176, 224, 230)', category: 'Blue', full: 'Powder Blue, #B0E0E6, (176, 224, 230)'},
        {id: 191, name: "Light Blue", backgroundColor: "#ADD8E6", hexcode: '#ADD8E6', rgb: '(173, 216, 230)', category: 'Blue', full: 'Light Blue, #ADD8E6, (173, 216, 230)'},
        {id: 192, name: "Light Sky Blue", backgroundColor: "#87CEFA", hexcode: '#87CEFA', rgb: '(135, 206, 250)', category: 'Blue', full: 'Light Sky Blue, #87CEFA, (135, 206, 250)'},
        {id: 193, name: "Sky Blue", backgroundColor: "#87CEEB", hexcode: '#87CEEB', rgb: '(135, 206, 235)', category: 'Blue', full: 'Sky Blue, #87CEEB, (135, 206, 235)'},
        {id: 194, name: "Deep Sky Blue", backgroundColor: "#00BFFF", hexcode: '#00BFFF', rgb: '(0, 191, 255)', category: 'Blue', full: 'Deep Sky Blue, #00BFFF, (0, 191, 255)'},
        {id: 195, name: "Cornflower Blue", backgroundColor: "#6495ED", hexcode: '#6495ED', rgb: '(100, 149, 237)', category: 'Blue', full: 'Cornflower Blue, #6495ED, (100, 149, 237)'},
        {id: 197, name: "Royal Blue", backgroundColor: "#4169E1", hexcode: '#4169E1', rgb: '(65, 105, 225)', category: 'Blue', full: 'Royal Blue, #4169E1, (65, 105, 225)', color: '#FFFFFF'},
        {id: 198, name: "Medium Blue", backgroundColor: "#0000CD", hexcode: '#0000CD', rgb: '(0, 0, 205)', category: 'Blue', full: 'Medium Blue, #0000CD, (0, 0, 205)', color: '#FFFFFF'},
        {id: 199, name: "Dark Blue", backgroundColor: "#00008B", hexcode: '#00008B', rgb: '(0, 0, 139)', category: 'Blue', full: 'Dark Blue, #00008B, (0, 0, 139)', color: '#FFFFFF'},
        {id: 200, name: "Navy", backgroundColor: "#000080", hexcode: '#000080', rgb: '(0, 0, 128)', category: 'Blue', full: 'Navy, #000080, (0, 0, 128)', color: '#FFFFFF'},
        {id: 201, name: "Midnight Blue", backgroundColor: "#191970", hexcode: '#191970', rgb: '(25, 25, 112)', category: 'Blue', full: 'Midnight Blue, #191970, (25, 25, 112)', color: '#FFFFFF'},
        {id: 202, name: "Light Gray", backgroundColor: "#D3D3D3", hexcode: '#D3D3D3', rgb: '(211, 211, 211)', category: 'Gray', full: 'Light Gray, #D3D3D3, (211, 211, 211)'},
        {id: 203, name: "Silver", backgroundColor: "#C0C0C0", hexcode: '#C0C0C0', rgb: '(192, 192, 192)', category: 'Gray', full: 'Silver, #C0C0C0, (192, 192, 192)'},
        {id: 204, name: "Dark Gray", backgroundColor: "#A9A9A9", hexcode: '#A9A9A9', rgb: '(169, 169, 169)', category: 'Gray', full: 'Dark Gray, #A9A9A9, (169, 169, 169)'},
        {id: 205, name: "Gray", backgroundColor: "#808080", hexcode: '#808080', rgb: '(128, 128, 128)', category: 'Gray', full: 'Gray, #808080, (128, 128, 128)', color: '#FFFFFF'},
        {id: 206, name: "Dim Gray", backgroundColor: "#696969", hexcode: '#696969', rgb: '(105, 105, 105)', category: 'Gray', full: 'Dim Gray, #696969, (105, 105, 105)', color: '#FFFFFF'},
        {id: 207, name: "Black", backgroundColor: "#000000", hexcode: '#000000', rgb: '(0, 0, 0)', category: 'Black', full: 'Black, #000000, (0, 0, 0)', color: '#FFFFFF'},
        {id: 208, name: "Dark Slate Gray", backgroundColor: "#2F4F4F", hexcode: '#2F4F4F', rgb: '(47, 79, 79)', category: 'Gray', full: 'Dark Slate Gray, #2F4F4F, (47, 79, 79)', color: '#FFFFFF'},
        {id: 209, name: "Light Slate Gray", backgroundColor: "#778899", hexcode: '#778899', rgb: '(119, 136, 153)', category: 'Gray', full: 'Light Slate Gray, #778899, (119, 136, 153)', color: '#FFFFFF'},
        {id: 210, name: "Slate Gray", backgroundColor: "#708090", hexcode: '#708090', rgb: '(112, 128, 144)', category: 'Gray', full: 'Slate Gray, #708090, (112, 128, 144)', color: '#FFFFFF'},
        {id: 211, name: "White", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FFFFFF", hexcode: '#FFFFFF', rgb: '(255, 255, 255)', category: 'White', full: 'White, #FFFFFF, (255, 255, 255)', color: '#000000'},
        {id: 212, name: "Snow", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FFFAFA", hexcode: '#FFFAFA', rgb: '(255, 250, 250)', category: 'White', full: 'Snow, #FFFAFA, (255, 250, 250)', color: '#000000'},
        {id: 213, name: "Honey Dew", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#F0FFF0", hexcode: '#F0FFF0', rgb: '(240, 255, 240)', category: 'White', full: 'Honey Dew, #F0FFF0, (240, 255, 240)', color: '#000000'},
        {id: 214, name: "Mint Cream", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#F5FFFA", hexcode: '#F5FFFA', rgb: '(245, 255, 250)', category: 'White', full: 'Mint Cream, #F5FFFA, (245, 255, 250)', color: '#000000'},
        {id: 215, name: "Azure", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#F0FFFF", hexcode: '#F0FFFF', rgb: '(240, 255, 255)', category: 'White', full: 'Azure, #F0FFFF, (240, 255, 255)', color: '#000000'},
        {id: 216, name: "Alice Blue", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#F0F8FF", hexcode: '#F0F8FF', rgb: '(240, 248, 255)', category: 'White', full: 'Alice Blue, #F0F8FF, (240, 248, 255)', color: '#000000'},
        {id: 217, name: "Ghost White", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#F8F8FF", hexcode: '#F8F8FF', rgb: '(248, 248, 255)', category: 'White', full: 'Ghost White, #F8F8FF, (248, 248, 255)', color: '#000000'},
        {id: 218, name: "Sea Shell", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FFF5EE", hexcode: '#FFF5EE', rgb: '(255, 245, 238)', category: 'White', full: 'Sea Shell, #FFF5EE, (255, 245, 238)', color: '#000000'},
        {id: 219, name: "Beige", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#F5F5DC", hexcode: '#F5F5DC', rgb: '(245, 245, 220)', category: 'White', full: 'Beige, #F5F5DC, (245, 245, 220)', color: '#000000'},
        {id: 220, name: "Old Lace", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FDF5E6", hexcode: '#FDF5E6', rgb: '(253, 245, 230)', category: 'White', full: 'Old Lace, #FDF5E6, (253, 245, 230)', color: '#000000'},
        {id: 221, name: "Floral White", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FFFAF0", hexcode: '#FFFAF0', rgb: '(255, 250, 240)', category: 'White', full: 'Floral White, #FFFAF0, (255, 250, 240)', color: '#000000'},
        {id: 222, name: "Ivory", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FFFFF0", hexcode: '#FFFFF0', rgb: '(255, 255, 240)', category: 'White', full: 'Ivory, #FFFFF0, (255, 255, 240)', color: '#000000'},
        {id: 223, name: "Antique White", backgroundColor: "#FAEBD7", hexcode: '#FAEBD7', rgb: '(250, 235, 215)', category: 'White', full: 'Antique White, #FAEBD7, (250, 235, 215)'},
        {id: 224, name: "Linen", backgroundColor: "#FAF0E6", hexcode: '#FAF0E6', rgb: '(250, 240, 230)', category: 'White', full: 'Linen, #FAF0E6, (250, 240, 230)'},
        {id: 225, name: "Lavender Blush", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FFF0F5", hexcode: '#FFF0F5', rgb: '(255, 240, 245)', category: 'White', full: 'Lavender Blush, #FFF0F5, (255, 240, 245)', color: '#000000'},
        {id: 226, name: "Misty Rose", backgroundColor: "#FFE4E1", hexcode: '#FFE4E1', rgb: '(255, 228, 225)', category: 'White', full: 'Misty Rose, #FFE4E1, (255, 228, 225)'},
        {id: 227, name: "Gold", backgroundColor: "#FFD700", hexcode: '#FFD700', rgb: '(255, 215, 0)', category: 'Yellow', full: 'Gold, #FFD700, (255, 215, 0)'},
        {id: 228, name: "Yellow", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FFFF00", hexcode: '#FFFF00', rgb: '(255, 255, 0)', category: 'Yellow', full: 'Yellow, #FFFF00, (255, 255, 0)', color: '#000000'},
        {id: 229, name: "Light Yellow", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FFFFE0", hexcode: '#FFFFE0', rgb: '(255, 255, 224)', category: 'Yellow', full: 'Light Yellow, #FFFFE0, (255, 255, 224)', color: '#000000'},
        {id: 230, name: "Light Golden Rod Yellow", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FAFAD2", hexcode: '#FAFAD2', rgb: '(250, 250, 210)', category: 'Yellow', full: 'Light Golden Rod Yellow, #FAFAD2, (250, 250, 210)', color: '#000000'},
        {id: 231, name: "Papaya Whip", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#FFEFD5", hexcode: '#FFEFD5', rgb: '(255, 239, 213)', category: 'Yellow', full: 'Papaya Whip, #FFEFD5, (255, 239, 213)', color: '#000000'},
        {id: 232, name: "Moccasin", backgroundColor: "#FFE4B5", hexcode: '#FFE4B5', rgb: '(255, 228, 181)', category: 'Yellow', full: 'Moccasin, #FFE4B5, (255, 228, 181)'},
        {id: 233, name: "Peach Puff", backgroundColor: "#FFDAB9", hexcode: '#FFDAB9', rgb: '(255, 218, 185)', category: 'Yellow', full: 'Peach Puff, #FFDAB9, (255, 218, 185)'},
        {id: 234, name: "Pale Golden Rod", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#EEE8AA", hexcode: '#EEE8AA', rgb: '(238, 232, 170)', category: 'Yellow', full: 'Pale Golden Rod, #EEE8AA, (238, 232, 170)', color: '#000000'},
        {id: 235, name: "Dark Khaki", backgroundColor: "#BDB76B", hexcode: '#BDB76B', rgb: '(189, 183, 107)', category: 'Yellow', full: 'Dark Khaki, #BDB76B, (189, 183, 107)'},
        {id: 236, name: "Khaki", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#F0E68C", hexcode: '#F0E68C', rgb: '(240, 230, 140)', category: 'Yellow', full: 'Khaki, #F0E68C, (240, 230, 140)', color: '#000000'},
        {id: 237, name: "Rose Quartz", backgroundColor: "#F7CAC9", hexcode: '#F7CAC9', rgb: '(247, 202, 201)', category: 'Pink', full: 'Rose Quartz, #F7CAC9, (247, 202, 201)'},
        {id: 238, name: "Serenity", backgroundColor: "#91A8D0", hexcode: '#91A8D0', rgb: '(145, 168, 208)', category: 'Blue', full: 'Serenity, #91A8D0, (145, 168, 208)'},
        {id: 239, name: "Peach Echo", backgroundColor: "#F7786B", hexcode: '#F7786B', rgb: '(247, 120, 107)', category: 'Orange', full: 'Peach Echo, #F7786B, (247, 120, 107)'},
        {id: 240, name: "Snorkel Blue", backgroundColor: "#004D8C", hexcode: '#004D8C', rgb: '(0, 77, 140)', category: 'Blue', full: 'Snorkel Blue, #004D8C, (0, 77, 140)', color: '#FFFFFF'},
        {id: 241, name: "Limpet Shell", backgroundColor: "#98DDDE", hexcode: '#98DDDE', rgb: '(152, 221, 222)', category: 'Blue', full: 'Limpet Shell, #98DDDE, (152, 221, 222)'},
        {id: 242, name: "Lilac Gray", backgroundColor: "#9896A4", hexcode: '#9896A4', rgb: '(152, 150, 164)', category: 'Purple', full: 'Lilac Gray, #9896A4, (152, 150, 164)'},
        {id: 243, name: "Fiesta", backgroundColor: "#DC443A", hexcode: '#DC443A', rgb: '(220, 68, 58)', category: 'Red', full: 'Fiesta, #DC443A, (220, 68, 58)', color: '#FFFFFF'},
        {id: 244, name: "Iced Coffee", backgroundColor: "#B18F6A", hexcode: '#B18F6A', rgb: '(177, 143, 106)', category: 'Brown', full: 'Iced Coffee, #B18F6A, (177, 143, 106)'},
        {id: 245, name: "Green Flash", backgroundColor: "#71CC51", hexcode: '#71CC51', rgb: '(113, 204, 81)', category: 'Green', full: 'Green Flash, #71CC51, (113, 204, 81)'},
        {id: 246, name: "Cerise", backgroundColor: "#DE3163", hexcode: '#DE3163', rgb: '(222, 49, 99)', category: 'Purple', full: 'Cerise, #DE3163, (222, 49, 99)', color: '#FFFFFF'},
        {id: 247, name: "Eggplant", backgroundColor: "#614051", hexcode: '#614051', rgb: '(97, 64, 81)', category: 'Purple', full: 'Eggplant, #614051, (97, 64, 81)', color: '#FFFFFF'},
        {id: 248, name: "Baker-Miller Pink", backgroundColor: "#FF91AF", hexcode: '#FF91AF', rgb: '(255, 145, 175)', category: 'Pink', full: 'Baker-Miller Pink, #FF91AF, (255, 145, 175)'},
        {id: 249, name: "Lilac", backgroundColor: "#C8A2C8", hexcode: '#C8A2C8', rgb: '(200, 162, 200)', category: 'Purple', full: 'Lilac, #C8A2C8, (200, 162, 200)'},
        {id: 250, name: "Blush", backgroundColor: "#DE5D83", hexcode: '#DE5D83', rgb: '(200, 162, 200)', category: 'Red', full: 'Blush, #DE5D83, (222, 93, 131)'},
        {id: 251, name: "Peter River", backgroundColor: "#3498DB", hexcode: '#3498DB', rgb: '(52, 152, 219)', category: 'Blue', full: 'Peter River, #3498DB, (52, 152, 219)', color: '#FFFFFF'},
        {id: 252, name: "Belize Hole", backgroundColor: "#2980B9", hexcode: '#2980B9', rgb: '(41, 128, 185)', category: 'Blue', full: 'Belize Hole, #2980B9, (41, 128, 185)', color: '#FFFFFF'},
        {id: 253, name: "Carrot", backgroundColor: "#E67E22", hexcode: '#E67E22', rgb: '(230, 126, 34)', category: 'Orange', full: 'Carrot, #E67E22, (230, 126, 34)'},
        {id: 254, name: "Sun Flower", backgroundColor: "#F1C40F", hexcode: '#F1C40F', rgb: '(241, 196, 15)', category: 'Yellow', full: 'Sun Flower, #F1C40F, (241, 196, 15)'},
        {id: 255, name: "Alizarin", backgroundColor: "#E74C3C", hexcode: '#E74C3C', rgb: '(231, 76, 60)', category: 'Red', full: 'Alizarin, #E74C3C, (231, 76, 60)'},
        {id: 256, name: "Amethyst", backgroundColor: "#9B59B6", hexcode: '#9B59B6', rgb: '(155, 89, 182)', category: 'Purple', full: 'Amethyst, #9B59B6, (155, 89, 182)', color: '#FFFFFF'},
        {id: 257, name: "Clouds", color: "#A9A9A9", opacityColor: '0.7', backgroundColor: "#ECF0F1", hexcode: '#ECF0F1', rgb: '(236, 240, 241)', category: 'Gray', full: 'Clouds, #ECF0F1, (236, 240, 241)', color: '#000000'},
        {id: 258, name: "Nephritis", backgroundColor: "#27AE60", hexcode: '#27AE60', rgb: '(39, 174, 96)', category: 'Green', full: 'Nephritis, #27AE60, (39, 174, 96)'},
        {id: 259, name: "DeviantArt", backgroundColor: "#05CC47", hexcode: '#05CC47', rgb: '(5, 204, 71)', category: 'Brand, Green', full: 'DeviantArt, #05CC47, (5, 204, 71)'},
        {id: 260, name: "Reddit", backgroundColor: "#FF5700", hexcode: '#FF5700', rgb: '(255, 87, 0)', category: 'Brand, Orange', full: 'Reddit, #FF5700, (255, 87, 0)', color: '#FFFFFF'},
        {id: 261, name: "Treehouse", backgroundColor: "#6EBC6C", hexcode: '#6EBC6C', rgb: '(111, 188, 109)', category: 'Brand, Green', full: 'Treehouse, #6EBC6C, (111, 188, 109)'},
        {id: 262, name: "Mozilla", backgroundColor: "#C13832", hexcode: '#C13832', rgb: '(193, 56, 50)', category: 'Brand, Red', full: 'Mozilla, #C13832, (193, 56, 50)', color: '#FFFFFF'},
        {id: 263, name: "Flipboard", backgroundColor: "#E12828", hexcode: '#E12828', rgb: '(225, 40, 40)', category: 'Brand, Red', full: 'Flipboard, #E12828, (225, 40, 40)', color: '#FFFFFF'},
        {id: 264, name: "Lynch", backgroundColor: "#6C7A89", hexcode: '#6C7A89', rgb: '(108, 122, 137)', category: 'Gray', full: 'Lynch, #6C7A89, (108, 122, 137)', color: '#FFFFFF'},
        {id: 265, name: "Sandstorm", backgroundColor: "#F9BF3B", hexcode: '#F9BF3B', rgb: '(249, 191, 59)', category: 'Orange', full: 'Sandstorm, #F9BF3B, (249, 191, 59)'},
        {id: 266, name: "Mountain Meadow", backgroundColor: "#1BBC9B", hexcode: '#1BBC9B', rgb: '(27, 188, 155)', category: 'Green', full: 'Mountain Meadow, #1BBC9B, (27, 188, 155)'},
        {id: 267, name: "Stanford University", backgroundColor: "#8C1515", hexcode: '#8C1515', rgb: '(140, 21, 21)', category: 'School, Red', full: 'Stanford University, #8C1515, (140, 21, 21)', color: '#FFFFFF'},
        {id: 268, name: "Ohio State University", backgroundColor: "#BB0000", hexcode: '#BB0000', rgb: '(187, 0, 0)', category: 'School, Red', full: 'Ohio State University, #BB0000, (187, 0, 0)', color: '#FFFFFF'},
        {id: 269, name: "Harvard University", backgroundColor: "#A51C30", hexcode: '#A51C30', rgb: '(102, 102, 102)', category: 'School, Red', full: 'Harvard University, #A51C30, (165, 28, 48)', color: '#FFFFFF'},
        {id: 270, name: "Yale University", backgroundColor: "#00356B", hexcode: '#00356B', rgb: '(0, 53, 107)', category: 'School, Blue', full: 'Yale University, #00356B, (0, 53, 107)', color: '#FFFFFF'},
        {id: 271, name: "Columbia University", backgroundColor: "#C4D8E2", hexcode: '#C4D8E2', rgb: '(196, 216, 226)', category: 'School, Blue', full: 'Columbia University, #C4D8E2, (196, 216, 226)'},
        {id: 272, name: "University of Florida", backgroundColor: "#0021A5", hexcode: '#0021A5', rgb: '(0, 33, 165)', category: 'School, Blue', full: 'University of Florida, #0021A5, (0, 33, 165)', color: '#FFFFFF'},
        {id: 273, name: "Syracuse University", backgroundColor: "#D44500", hexcode: '#D44500', rgb: '(212, 69, 0)', category: 'School, Orange', full: 'Syracuse University, #D44500, (212, 69, 0)', color: '#FFFFFF'},
        {id: 274, name: "University of Michigan", backgroundColor: "#00274C", hexcode: '#00274C', rgb: '(250, 70, 22)', category: 'School, Blue', full: 'University of Michigan, #00274C, (0, 39, 76)', color: '#FFFFFF'},
        {id: 275, name: "Morehouse College", backgroundColor: "#900027", hexcode: '#900027', rgb: '(145, 0, 40)', category: 'School, Maroon', full: 'Morehouse College, #900027, (145, 0, 40)', color: '#FFFFFF'},
        {id: 276, name: "Spelman College", backgroundColor: "#2A3B86", hexcode: '#2A3B86', rgb: '(42, 59, 134)', category: 'School, Blue', full: 'Spelman College, #2A3B86, (42, 59, 134)', color: '#FFFFFF'},
        {id: 277, name: "UCLA", backgroundColor: "#3284BF", hexcode: '#3284BF', rgb: '(50, 132, 191)', category: 'School, Blue', full: 'UCLA, #3284BF, (50, 132, 191)', color: '#FFFFFF'},
        {id: 278, name: "University of Texas", backgroundColor: "#BF5700", hexcode: '#BF5700', rgb: '(191, 87, 0)', category: 'School, Orange', full: 'University of Texas, #BF5700, (191, 87, 0)', color: '#FFFFFF'},
        {id: 279, name: "University of Notre Dame", backgroundColor: "#0C2340", hexcode: '#0C2340', rgb: '(12, 35, 64)', category: 'School, Blue', full: 'University of Notre Dame, #0C2340, (12, 35, 64)', color: '#FFFFFF'},
        {id: 280, name: "Trivago", backgroundColor: "#017FAD", hexcode: '#017FAD', rgb: '(1, 127, 173)', category: 'Brand, Blue', full: 'Trivago, #017FAD, (1, 127, 173)', color: '#FFFFFF'},
        {id: 281, name: "University of Minnesota", backgroundColor: "#FFCC33", hexcode: '#FFCC33', rgb: '(255, 204, 51)', category: 'School, Gold', full: 'University of Minnesota, #FFCC33, (255, 204, 51)'},
        {id: 282, name: "Clemson University", backgroundColor: "#F66733", hexcode: '#F66733', rgb: '(234, 106, 32)', category: 'School, Orange', full: 'Clemson University, #F66733, (234, 106, 32)', color: '#FFFFFF'},
        {id: 283, name: "Florida State University", backgroundColor: "#CEB888", hexcode: '#CEB888', rgb: '(206, 184, 136)', category: 'School, Gold', full: 'Florida State University, #CEB888, (206, 184, 136)'},
        {id: 284, name: "Georgia State University", backgroundColor: "#0039A6", hexcode: '#0039A6', rgb: '(0, 57, 166)', category: 'School, Blue', full: 'Georgia State University, #0039A6, (0, 57, 166)', color: '#FFFFFF'},
        {id: 285, name: "Siemens", backgroundColor: "#009999", hexcode: '#009999', rgb: '(0, 153, 153)', category: 'Brand, Green, Blue', full: 'Siemens, #009999, (0, 153, 153)', color: '#FFFFFF'},
        {id: 286, name: "John Deere", backgroundColor: "#367C2B", hexcode: '#367C2B', rgb: '(54, 124, 43)', category: 'Brand, Green', full: 'John Deere, #367C2B, (54, 124, 43)', color: '#FFFFFF'},
        {id: 287, name: "PayPal", backgroundColor: "#0079C1", hexcode: '#0079C1', rgb: '(0, 121, 193)', category: 'Brand, Blue', full: 'PayPal, #0079C1, (0, 121, 193)', color: '#FFFFFF'},
        {id: 288, name: "Target", backgroundColor: "#D61A32", hexcode: '#D61A32', rgb: '(215, 27, 50)', category: 'Brand, Red', full: 'Target, #D61A32, (215, 27, 50)', color: '#FFFFFF'},
        {id: 289, name: "ArtRabbit", backgroundColor: "#252525", hexcode: '#252525', rgb: '(37, 37, 37)', category: 'Brand, Black', full: 'ArtRabbit, #252525, (37, 37, 37)', color: '#FFFFFF'},
        {id: 290, name: "Channel Hue", backgroundColor: "#FFDE16", hexcode: '#FFDE16', rgb: '(255, 222, 22)', category: 'Brand, Yellow', full: 'Channel Hue, #FFDE16, (255, 222, 22)'},
        {id: 291, name: "Shopify", backgroundColor: "#7AB55C", hexcode: '#7AB55C', rgb: '(115, 169, 80)', category: 'Brand, Green', full: 'Shopify, #7AB55C, (115, 169, 80)'},
        {id: 292, name: "University of Georgia", backgroundColor: "#BA0C2F", hexcode: '#BA0C2F', rgb: '(186, 12, 47)', category: 'School, Red', full: 'University of Georgia, #BA0C2F, (186, 12, 47)', color: '#FFFFFF'},
        {id: 293, name: "Georgia Tech", backgroundColor: "#EEB211", hexcode: '#EEB211', rgb: '(238, 178, 17)', category: 'School, Gold', full: 'Georgia Tech, #EEB211, (238, 178, 17)'},
        {id: 294, name: "Spotify", backgroundColor: "#1ED760", hexcode: '#1ED760', rgb: '(30, 215, 96)', category: 'Brand, Green', full: 'Spotify, #1ED760, (30, 215, 96)'},
        {id: 295, name: "Gainsboro", backgroundColor: "#DCDCDC", hexcode: '#DCDCDC', rgb: '(220, 220, 220)', category: 'Gray', full: 'Gainsboro, #DCDCDC, (220, 220, 220)'},
        {id: 296, name: "Kickstarter", backgroundColor: "#2BDE73", hexcode: '#2BDE73', rgb: '(43, 222, 115)', category: 'Brand, Green', full: 'Kickstarter, #2BDE73, (43, 222, 115)'},
        {id: 297, name: "Ohio State University", backgroundColor: "#666666", hexcode: '#666666', rgb: '(102, 102, 102)', category: 'School, Gray', full: 'Ohio State University, #666666, (102, 102, 102)', color: '#FFFFFF'},
        {id: 298, name: "Harvard University", backgroundColor: "#565656", hexcode: '#565656', rgb: '(86, 86, 86)', category: 'School, Gray', full: 'Harvard University, #565656, (86, 86, 86)', color: '#FFFFFF'},
        {id: 299, name: "University of Florida", backgroundColor: "#FA4616", hexcode: '#FA4616', rgb: '(250, 70, 22)', category: 'School, Orange', full: 'University of Florida, #FA4616, (250, 70, 22)', color: '#FFFFFF'},
        {id: 300, name: "University of Michigan", backgroundColor: "#FFCB05", hexcode: '#FFCB05', rgb: '(250, 70, 22)', category: 'School, Yellow', full: 'University of Michigan, #FFCB05, (255, 203, 5)'},
        {id: 301, name: "UCLA", backgroundColor: "#FFE600", hexcode: '#FFE600', rgb: '(255, 230, 0)', category: 'School, Yellow', full: 'UCLA, #FFE600, (255, 230, 0)'},
        {id: 302, name: "University of Notre Dame", backgroundColor: "#C99700", hexcode: '#C99700', rgb: '(201, 151, 0)', category: 'School, Yellow', full: 'University of Notre Dame, #C99700, (201, 151, 0)'},
        {id: 303, name: "University of Notre Dame", backgroundColor: "#AE9142", hexcode: '#AE9142', rgb: '(174, 143, 64)', category: 'School, Yellow', full: 'University of Notre Dame, #AE9142, (174, 143, 64)'},
        {id: 304, name: "Trivago", backgroundColor: "#F29020", hexcode: '#F29020', rgb: '(242, 144, 32)', category: 'Brand, Orange', full: 'Trivago, #F29020, (242, 144, 32)'},
        {id: 305, name: "Trivago", backgroundColor: "#CA4A2F", hexcode: '#CA4A2F', rgb: '(202, 74, 47)', category: 'Brand, Red', full: 'Trivago, #CA4A2F, (202, 74, 47)', color: '#FFFFFF'},
        {id: 306, name: "University of Minnesota", backgroundColor: "#7A0019", hexcode: '#7A0019', rgb: '(122, 0, 25)', category: 'School, Red, Brown', full: 'University of Minnesota, #7A0019, (122, 0, 25)', color: '#FFFFFF'},
        {id: 307, name: "Clemson University", backgroundColor: "#522D80", hexcode: '#522D80', rgb: '(82, 45, 128)', category: 'School, Purple', full: 'Clemson University, #522D80, (82, 45, 128)', color: '#FFFFFF'},
        {id: 308, name: "Florida State University", backgroundColor: "#782F40", hexcode: '#782F40', rgb: '(120, 47, 64)', category: 'School, Red', full: 'Florida State University, #782F40, (120, 47, 64)', color: '#FFFFFF'},
        {id: 309, name: "John Deere", backgroundColor: "#FFDE00", hexcode: '#FFDE00', rgb: '(255, 222, 0)', category: 'Brand, Yellow', full: 'John Deere, #FFDE00, (255, 222, 0)'},
        {id: 310, name: "PayPal", backgroundColor: "#00457C", hexcode: '#00457C', rgb: '(0, 69, 124)', category: 'Brand, Blue', full: 'PayPal, #00457C, (0, 69, 124)', color: '#FFFFFF'},
        {id: 311, name: "ArtRabbit", backgroundColor: "#1E28C1", hexcode: '#1E28C1', rgb: '(30, 40, 193)', category: 'Brand, Blue', full: 'ArtRabbit, #1E28C1, (30, 40, 193)', color: '#FFFFFF'},
        {id: 312, name: "ArtRabbit", backgroundColor: "#008D51", hexcode: '#008D51', rgb: '(0, 141, 81)', category: 'Brand, Green', full: 'ArtRabbit, #008D51, (0, 141, 81)', color: '#FFFFFF'},
        {id: 313, name: "ArtRabbit", backgroundColor: "#FF2B2B", hexcode: '#FF2B2B', rgb: '(255, 43, 43)', category: 'Brand, Red', full: 'ArtRabbit, #FF2B2B, (255, 43, 43)', color: '#FFFFFF'},
        {id: 314, name: "Channel Hue", backgroundColor: "#14C1EF", hexcode: '#14C1EF', rgb: '(20, 193, 239)', category: 'Brand, Blue', full: 'Channel Hue, #14C1EF, (20, 193, 239)'},
        {id: 315, name: "Mississippi State University", backgroundColor: "#660000", hexcode: '#660000', rgb: '(102, 0, 0)', category: 'School, Maroon', full: 'Mississippi State University, #660000, (102, 0, 0)', color: '#FFFFFF'},
        {id: 316, name: "University of Alabama", backgroundColor: "#9E1B32", hexcode: "#9E1B32", rgb: '(158, 27, 50)', category: 'School, Red', full: 'University of Alabama, #9E1B32, (158, 27, 50)', color: '#FFFFFF'},
        {id: 317, name: "University of Arkansas", backgroundColor: "#9D2235", hexcode: "#9D2235", rgb: '(157, 34, 53)', category: 'School, Red', full: 'University of Arkansas, #9D2235, (157, 34, 53)', color: '#FFFFFF'},
        {id: 318, name: "Auburn University", backgroundColor: "#0C2340", hexcode: "#0C2340", rgb: '(12, 35, 64)', category: 'School, Blue', full: 'Auburn University, #0C2340, (12, 35, 64)', color: '#FFFFFF'},
        {id: 319, name: "Auburn University", backgroundColor: "#E87722", hexcode: "#E87722", rgb: '(232, 119, 34)', category: 'School, Orange', full: 'Auburn University, #E87722, (232, 119, 34)', color: '#FFFFFF'},
        {id: 320, name: "University of Kentucky", backgroundColor: "#0033A0", hexcode: "#0033A0", rgb: '(0, 51, 160)', category: 'School, Blue', full: 'University of Kentucky, #0033A0, (0, 51, 160)', color: '#FFFFFF'},
        {id: 321, name: "University of Tennessee", backgroundColor: "#FF8200", hexcode: "#FF8200", rgb: '(255, 130, 0)', category: 'School, Orange', full: 'University of Tennessee, #FF8200, (255, 130, 0)', color: '#FFFFFF'},
        {id: 322, name: "Vanderbilt University", backgroundColor: "#866D4B", hexcode: "#866D4B", rgb: '(134, 109, 75)', category: 'School, Gold', full: 'Vanderbilt University, #866D4B, (134, 109, 75)', color: '#FFFFFF'},
        {id: 323, name: "Virginia Tech", backgroundColor: "#630031", hexcode: "#630031", rgb: '(99, 0, 49)', category: 'School, Maroon', full: 'Virginia Tech, #630031, (99, 0, 49)', color: '#FFFFFF'},
        {id: 324, name: "Virginia Tech", backgroundColor: "#CF4420", hexcode: "#CF4420", rgb: '(207, 69, 32)', category: 'School, Orange', full: 'Virginia Tech, #CF4420, (207, 69, 32)', color: '#FFFFFF'},
        {id: 325, name: "University of Washington", backgroundColor: "#4B2E83", hexcode: "#4B2E83", rgb: '(51, 0, 111)', category: 'School, Purple', full: 'University of Washington, #4B2E83, (51, 0, 111)', color: '#FFFFFF'},
        {id: 326, name: "University of Washington", backgroundColor: "#B7A57A", hexcode: "#B7A57A", rgb: '(232, 211, 162)', category: 'School, Gold', full: 'University of Washington, #B7A57A, (232, 211, 162)', color: '#FFFFFF'},
        {id: 327, name: "University of Washington", backgroundColor: "#85754D", hexcode: "#85754D", rgb: '(145, 123, 76)', category: 'School, Gold', full: 'University of Washington, #85754D, (145, 123, 76)', color: '#FFFFFF'},
        {id: 328, name: "Los Angeles Lakers", backgroundColor: "#FDBA21", hexcode: "#FDBA21", rgb: '(253, 186, 33)', category: 'NBA, Gold', full: 'Los Angeles Lakers, #FDBA21, (253, 186, 33)', color: '#000000'},
        {id: 329, name: "Los Angeles Lakers", backgroundColor: "#552084", hexcode: "#552084", rgb: '(85, 32, 132)', category: 'NBA, Purple', full: 'Los Angeles Lakers, #552084, (85, 32, 132)', color: '#FFFFFF'},
        {id: 330, name: "Mailchimp", backgroundColor: "#FFE01B", hexcode: "#FFE01B", rgb: '(255, 224, 27)', category: 'Brand, Yellow', full: 'Mailchimp, #FFE01B, (255, 224, 27)', color: '#000000'},
        {id: 331, name: "Mailchimp", backgroundColor: "#241C15", hexcode: "#241C15", rgb: '(36, 28, 21)', category: 'Brand, Black', full: 'Mailchimp, #241C15, (36, 28, 21)', color: '#FFFFFF'},
        {id: 332, name: "Golden State Warriors", backgroundColor: "#1D428A", hexcode: "#1D428A", rgb: '(29,66,138)', category: 'NBA, Blue', full: 'Golden State Warriors, #1D428A, (29,66,138)', color: '#FFFFFF'},
        {id: 333, name: "Golden State Warriors", backgroundColor: "#FFC72C", hexcode: "#FFC72C", rgb: '(255,199,44)', category: 'NBA, Yellow', full: 'Golden State Warriors, #FFC72C, (255,199,44)', color: '#000000'},
        {id: 334, name: "Lyft", backgroundColor: "#FF00BF", hexcode: "#FF00BF", rgb: '(255,0,191)', category: 'Brand, Pink', full: 'Lyft, #FF00BF, (255,0,191)', color: '#000000', color: '#FFFFFF'},
        {id: 335, name: "Slack", backgroundColor: "#36C5F0", hexcode: "#36C5F0", rgb: '(54,197,240)', category: 'Brand, Blue', full: 'Slack, #36C5F0, (54,197,240)', color: '#FFFFFF'},
        {id: 336, name: "Slack", backgroundColor: "#2EB67D", hexcode: "#2EB67D", rgb: '(46,182,125)', category: 'Brand, Green', full: 'Slack, #2EB67D, (46 182 125)', color: '#FFFFFF'},
        {id: 337, name: "Slack", backgroundColor: "#E01E5A", hexcode: "#E01E5A", rgb: '(224,30,90)', category: 'Brand, Red', full: 'Slack, #E01E5A, (224 30 90)', color: '#FFFFFF'},
        {id: 338, name: "Slack", backgroundColor: "#ECB22E", hexcode: "#ECB22E", rgb: '(236,178,46)', category: 'Brand, Yellow', full: 'Slack, #ECB22E, (236,178,46)', color: '#000000'},
        {id: 339, name: "Microsoft", backgroundColor: "#F25022", hexcode: "#F25022", rgb: '(242,80,34)', category: 'Brand, Orange, Red', full: 'Microsoft, #F25022, (242,80,34)', color: '#FFFFFF'},
        {id: 340, name: "Microsoft", backgroundColor: "#7FBA00", hexcode: "#7FBA00", rgb: '(127,186,0)', category: 'Brand, Green', full: 'Microsoft, #7FBA00, (127,186,0)', color: '#FFFFFF'},
        {id: 341, name: "Microsoft", backgroundColor: "#00A4EF", hexcode: "#00A4EF", rgb: '(0,164,239)', category: 'Brand, Blue', full: 'Microsoft, #00A4EF, (0,164,239)', color: '#FFFFFF'},
        {id: 342, name: "Microsoft", backgroundColor: "#FFB900", hexcode: "#FFB900", rgb: '(255,185,0)', category: 'Brand, Yellow', full: 'Microsoft, #FFB900, (255,185,0)', color: '#000000'},
        {id: 343, name: "Portland Trail Blazers", backgroundColor: "#E03A3E", hexcode: "#E03A3E", rgb: '(224,58,62)', category: 'NBA, Red', full: 'Portland Trail Blazers, #E03A3E, (224,58,62)', color: '#FFFFFF'},
        {id: 344, name: "Miami Heat", backgroundColor: "#98002E", hexcode: "#98002E", rgb: '(152,0,46)', category: 'NBA, Red', full: 'Miami Heat, #98002E, (152,0,46)', color: '#FFFFFF'},
        {id: 345, name: "Miami Heat", backgroundColor: "#F9A01B", hexcode: "#F9A01B", rgb: '(249,160,27)', category: 'NBA, Yellow', full: 'Miami Heat, #F9A01B, (249,160,27)', color: '#000000'},
        {id: 346, name: "Milwaukee Bucks", backgroundColor: "#00471B", hexcode: "#00471B", rgb: '(0,71,27)', category: 'NBA, Green', full: 'Milwaukee Bucks, #00471B, (0,71,27)', color: '#FFFFFF'},
        {id: 347, name: "Milwaukee Bucks", backgroundColor: "#EEE1C6", hexcode: "#EEE1C6", rgb: '(240,235,210)', category: 'NBA, Yellow', full: 'Milwaukee Bucks, #EEE1C6, (240,235,210)', color: '#000000'},
        {id: 348, name: "Phoenix Suns", backgroundColor: "#1D1160", hexcode: "#1D1160", rgb: '(29,17,96)', category: 'NBA, Purple', full: 'Phoenix Suns, #1D1160, (29,17,96)', color: '#FFFFFF'},
        {id: 349, name: "Phoenix Suns", backgroundColor: "#E56020", hexcode: "#E56020", rgb: '(229,95,32)', category: 'NBA, Orange', full: 'Phoenix Suns, #E56020, (229,95,32)', color: '#FFFFFF'},
        {id: 350, name: "Memphis Grizzlies", backgroundColor: "#5D76A9", hexcode: "#5D76A9", rgb: '(229,95,32)', category: 'NBA, Blue', full: 'Memphis Grizzlies, #5D76A9, (229,95,32)', color: '#FFFFFF'},
        {id: 351, name: "Memphis Grizzlies", backgroundColor: "#12173F", hexcode: "#12173F", rgb: '(18,23,63)', category: 'NBA, Blue', full: 'Memphis Grizzlies, #12173F, (18,23,63)', color: '#FFFFFF'},
        {id: 352, name: "Seattle Supersonics", backgroundColor: "#00653A", hexcode: "#00653A", rgb: '(18,23,63)', category: 'NBA, Green', full: 'Seattle Supersonics, #00653A, (18,23,63)', color: '#FFFFFF'},
        {id: 353, name: "Seattle Supersonics", backgroundColor: "#FFC200", hexcode: "#FFC200", rgb: '(255,194,32)', category: 'NBA, Yellow', full: 'Seattle Supersonics, #FFC200, (255,194,32)', color: '#000000'},
        {id: 354, name: "Charlotte Hornets", backgroundColor: "#1D1160", hexcode: "#1D1160", rgb: '(29,17,96)', category: 'NBA, Purple', full: 'Charlotte Hornets, #1D1160, (29,17,96)', color: '#FFFFFF'},
        {id: 355, name: "Charlotte Hornets", backgroundColor: "#00788C", hexcode: "#00788C", rgb: '(0,120,140)', category: 'NBA, Blue', full: 'Charlotte Hornets, #00788C, (0,120,140)', color: '#FFFFFF'},
        {id: 356, name: "Minnesota Timberwolves", backgroundColor: "#0C2340", hexcode: "#0C2340", rgb: '(12,35,64)', category: 'NBA, Blue', full: 'Minnesota Timberwolves, #0C2340, (12,35,64)', color: '#FFFFFF'},
        {id: 357, name: "Minnesota Timberwolves", backgroundColor: "#236192", hexcode: "#236192", rgb: '(35,97,146)', category: 'NBA, Blue', full: 'Minnesota Timberwolves, #236192, (35,97,146)', color: '#FFFFFF'},
        {id: 358, name: "Minnesota Timberwolves", backgroundColor: "#78BE20", hexcode: "#78BE20", rgb: '(120,190,32)', category: 'NBA, Green', full: 'Minnesota Timberwolves, #78BE20, (120,190,32)', color: '#FFFFFF'},
        {id: 359, name: "Boston Celtics", backgroundColor: "#007A33", hexcode: "#007A33", rgb: '(0,122,51)', category: 'NBA, Green', full: 'Boston Celtics, #007A33, (0,122,51)', color: '#FFFFFF'},
        {id: 360, name: "Boston Celtics", backgroundColor: "#BA9653", hexcode: "#BA9653", rgb: '(139,111,78)', category: 'NBA, Gold', full: 'Boston Celtics, #BA9653, (139,111,78)', color: '#FFFFFF'},
        {id: 361, name: "Cleveland Cavaliers", backgroundColor: "#860038", hexcode: "#860038", rgb: '(134,0,56)', category: 'NBA, Red, Brown', full: 'Cleveland Cavaliers, #860038, (134,0,56)', color: '#FFFFFF'},
        {id: 362, name: "Cleveland Cavaliers", backgroundColor: "#041E42", hexcode: "#041E42", rgb: '(4,30,66)', category: 'NBA, Blue', full: 'Cleveland Cavaliers, #041E42, (4,30,66)', color: '#FFFFFF'},
        {id: 363, name: "Cleveland Cavaliers", backgroundColor: "#FDBB30", hexcode: "#FDBB30", rgb: '(253,187,48)', category: 'NBA, Gold', full: 'Cleveland Cavaliers, #FDBB30, (253,187,48)', color: '#000000'}
      ]
    };
  }
  
  render() {
    return (
      <>
        <Header />
        <Cards colorDetails={this.state.details} />
        <Footer />
      </>
    );
  }
}

export default App;
